import { H3, XSmall } from "@/styles/Type"
import { ArrowRightIcon } from "@/icons/ArrowRight"
import React, { Suspense, useCallback } from "react"
import { CloseIcon } from "@/icons/Close"

import clsx from "clsx"
import useProfileCompletion from "@/hooks/useProfileCompletion"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { commonSelectors } from "@/store/common/selectors"
import { commonActions } from "@/store/common/commonSlice"
import REDUX_MODAL_KEYS from "@/lib/modals"
import Button from "../Button"

const ChecklistModal = React.lazy(() => import("./ChecklistModal"))

const ProfileCompletionState = React.memo(
  ({ collapsed }: { collapsed: boolean }) => {
    const dispatch = useAppDispatch()
    const modals = useAppSelector(commonSelectors.modals)
    const { checkPoints, completionsDone } = useProfileCompletion()

    const handleToggle = useCallback(
      (visible = false) => {
        dispatch(
          commonActions.setModal({
            key: REDUX_MODAL_KEYS.draftProfileChecklist,
            visible,
          })
        )
      },
      [dispatch]
    )

    const mileStones = checkPoints
      .map((checkpoint) => ({
        id: checkpoint.key,
        completed: completionsDone?.includes(checkpoint.key),
      }))
      .sort((a, b) => {
        if (a.completed && !b.completed) {
          return -1
        }
        if (!a.completed && b.completed) {
          return 1
        }
        return 0
      })

    const allMilestonesCompleted = mileStones.every(
      (milestone) => milestone.completed
    )

    const isDialogOpen = modals?.draftProfileChecklist

    return (
      <div className="p-2">
        {!collapsed && !allMilestonesCompleted && (
          <div className="border-[1px] rounded-md border-charcoal p-3 relative w-full ">
            <CloseIcon className="absolute cursor-pointer top-2 right-2 sr-only" />
            <H3 as="a" className="text-cream mb-2 font-medium">
              {allMilestonesCompleted ? "Profile Completed!" : "Get Started"}
            </H3>
            <div className="flex gap-1 mt-2">
              {mileStones.map((milestone, index) => (
                <div
                  key={milestone.id}
                  className={clsx(
                    "w-[39px] h-[9px]",
                    index === 0 ? "rounded-l-md" : "",
                    index === mileStones.length - 1 ? "rounded-r-md" : "",
                    milestone.completed ? "bg-gold" : "bg-charcoal"
                  )}
                />
              ))}
            </div>
            <div className="mt-2">
              <XSmall className="text-medgray">
                Start promoting your profile by completing all these steps.
              </XSmall>
            </div>
            {allMilestonesCompleted ? null : (
              <Button
                className="mt-6 mb-2 text-bronze font-medium"
                theme="custom"
                size="custom"
                onClick={() => handleToggle(true)}
                disableTransition
              >
                My Checklist
                <ArrowRightIcon stroke="" className="ml-2" />
              </Button>
            )}
          </div>
        )}
        <Suspense fallback={null}>
          <ChecklistModal open={isDialogOpen} onOpenChange={handleToggle} />
        </Suspense>
      </div>
    )
  }
)

ProfileCompletionState.displayName = "UserProfileCompletionState"

export default ProfileCompletionState
