"use client"

/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react"
import Link from "next/link"
import { XSMed } from "@/styles/Type"
import useAuth from "@/hooks/useAuth"
import AmpersandMarkIcon from "@/icons/AmpersandMark"
import Button from "@/components/Button"
import BurgerMenuIcon from "@/icons/BurgerMenu"
import { DashboardLink } from "@/components/SidebarMenu"
import { SidebarLink, SidebarParent } from "@/components/SidebarMenu/types"
import { usePathname } from "next/navigation"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import MasqueradeSwitcher from "@/components/GlobalLayout/MasqueradeSwitcher"
import UserProfileCompletionState from "@/components/UserOnboarding/UserProfileCompletionState"
import { NavigationConfig } from "@/lib/sidebarHelper"
import useUserCompletion from "@/hooks/useUserCompletion"
import { CHECKPOINT_SIDEBAR_ITEM_MAP } from "@/components/UserOnboarding/utils"
import { CHECKPOINT_SIDEBAR_ITEM_MAP as PROFILE_CHECKPOINT_SIDEBAR_ITEM_MAP } from "@/components/ProfileOnboarding/utils"
import ProfileCompletionState from "@/components/ProfileOnboarding/ProfileCompletionState"
import useProfileCompletion from "@/hooks/useProfileCompletion"
import { useChatContext } from "stream-chat-react"
import debounce from "@/lib/debounce"

const Sidebar = ({
  toggle,
  collapsed,
  links,
  navigationConfig,
}: {
  toggle: (newState?: boolean) => void
  collapsed: boolean
  links: (SidebarLink | SidebarParent)[]
  navigationConfig: NavigationConfig
}) => {
  const { theme, variant } = navigationConfig
  const { user, masquerade } = useAuth()
  const pathname = usePathname()
  const { checkPoints, completionsDone } = useUserCompletion()
  const {
    checkPoints: profileCheckPoints,
    completionsDone: profileCompletionsDone,
  } = useProfileCompletion()
  const { client } = useChatContext()
  const sidebarToggleRef = React.useRef<(newState?: boolean) => void>(toggle)

  sidebarToggleRef.current = toggle

  // States
  // const [sideBarBorder, setSideBarBorder] = useState(false)
  const [menuValue, setMenuValue] = useState(
    links?.find(
      (link) =>
        ("href" in link && pathname.includes(link.href)) ||
        ("children" in link &&
          link.children.some((child) => pathname.includes(child.href)))
    )?.label
  )
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    const fetchTotalUnreadCount = async () => {
      let filters: any = {
        type: "messaging",
        members: { $in: [user?.stream_id] },
      }
      if (masquerade?.slug) {
        filters = {
          ...filters,
          $or: [
            {
              from_profile_slug: {
                $in: [masquerade?.slug],
              },
            },
            {
              to_profile_slugs: {
                $in: [[masquerade?.slug]],
              },
            },
          ],
        }
      }

      const channels = await client.queryChannels(filters, undefined, {
        limit: 100,
        offset: 0,
      })
      let totalUnreadCount = 0
      channels
        .filter((c) => !c.id.includes("system_message_channel"))
        .forEach((channel) => {
          totalUnreadCount += channel.countUnread()
        })
      setUnreadCount(totalUnreadCount)
    }
    const debouncedFetchTotalUnreadCount = debounce(fetchTotalUnreadCount, 1000)
    client.on("message.read", debouncedFetchTotalUnreadCount)

    debouncedFetchTotalUnreadCount()
    return () => client.off("message.read", debouncedFetchTotalUnreadCount)
  }, [client, masquerade?.slug, user?.stream_id])

  useEffect(() => {
    if (pathname.includes("/brand/") || pathname.includes("/artist/")) {
      sidebarToggleRef.current(true)
    }
  }, [pathname])

  const isMasqueradeAdmin = useMemo(() => {
    const myMasqueradeUser = masquerade?.active_users?.find(
      ({ email }) => email === user?.email
    )
    return (
      myMasqueradeUser?.access_type === "Admin" ||
      myMasqueradeUser?.access_type === "Owner"
    )
  }, [masquerade, user])

  const navLinks = useMemo(() => {
    return links.map((link) => {
      const getAddon = (label) => {
        if (
          !user?.metadata ||
          (!checkPoints?.length && !profileCheckPoints?.length)
        ) {
          return null
        }
        if (["Messaging", "Inbox"].includes(label) && unreadCount > 0) {
          return (
            <span className="w-[8px] h-[8px] rounded-full bg-[#F04438] ml-auto block" />
          )
        }
        if (
          variant === "personalDashboard" &&
          checkPoints?.length &&
          checkPoints.find((c) => CHECKPOINT_SIDEBAR_ITEM_MAP[c.key] === label)
        ) {
          return (
            !completionsDone?.find(
              (c) => CHECKPOINT_SIDEBAR_ITEM_MAP[c] === label
            ) && (
              <span className="w-[8px] h-[8px] rounded-full bg-[#F04438] ml-auto block" />
            )
          )
        }
        if (
          variant === "dashboard" &&
          profileCheckPoints?.length &&
          profileCheckPoints.find(
            (c) =>
              PROFILE_CHECKPOINT_SIDEBAR_ITEM_MAP[c.key]?.replace(
                "{{type}}",
                masquerade?.type === "artist" ? "Talent" : "Brand"
              ) === label
          )
        ) {
          return (
            !profileCompletionsDone?.find(
              (c) =>
                PROFILE_CHECKPOINT_SIDEBAR_ITEM_MAP[c]?.replace(
                  "{{type}}",
                  masquerade?.type === "artist" ? "Talent" : "Brand"
                ) === label
            ) && (
              <span className="w-[8px] h-[8px] rounded-full bg-[#F04438] ml-auto block" />
            )
          )
        }
        return null
      }
      if ("children" in link) {
        const childrenWithAddon = link.children.map((child) => {
          return {
            ...child,
            addonAfter: user?.metadata ? getAddon(child.label) : undefined,
          }
        })
        return {
          ...link,
          children: childrenWithAddon,
          addonAfter: childrenWithAddon?.some((child) => child.addonAfter) ? (
            <span className="w-[8px] h-[8px] rounded-full bg-[#F04438] ml-auto block" />
          ) : undefined,
        }
      }
      return {
        ...link,
        addonAfter: user?.metadata ? getAddon(link.label) : undefined,
      }
    })
  }, [
    user?.metadata,
    checkPoints,
    completionsDone,
    links,
    masquerade?.type,
    profileCheckPoints,
    profileCompletionsDone,
    unreadCount,
    variant,
  ])

  return (
    <aside
      id="default-sidebar"
      className={`fixed top-0 left-0 z-40 ${collapsed ? "w-[72px]" : "w-[249px]"} ${
        theme === "dark"
          ? `bg-offblack border-r border-gunmetal`
          : `bg-spring border-r`
      } h-screen transition-all -translate-x`}
      aria-label="Sidebar"
    >
      <div className="h-full">
        <div
          className={`sidebar-header flex items-center h-[54px] ${collapsed ? "justify-center pl-0" : "pl-[1.5rem]"}`}
        >
          <Button
            theme="custom"
            size="custom"
            onClick={() => toggle()}
            as="button"
            className={`${collapsed ? "" : "mr-5"} ${
              theme === "dark"
                ? "bg-offblack text-white"
                : "text-gunmetal bg-spring"
            }`}
          >
            <BurgerMenuIcon />
          </Button>
          {!collapsed && (
            <Link
              href="/discover"
              passHref
              className={`${theme === "dark" ? "text-white" : "text-offblack"} hidden lg:block`}
            >
              <AmpersandMarkIcon width="42" height="22" />
              <span className="sr-only">R&amp;D</span>
            </Link>
          )}
        </div>
        <div className="flex flex-col justify-between h-[95%]">
          <div className="sidebar-content px-3 py-4 pt-[0]">
            <div
              className={`items-start mt-[17px] pt-[8px] px-3 pb-[20px] mb-[14px] ${
                theme === "dark"
                  ? "bg-offblack text-white hover:bg-[rgba(255,255,255,0.04)] hover:rounded-lg"
                  : "text-gunmetal bg-spring hover:bg-[rgba(184,159,91,0.10)] hover:rounded-lg"
              } ${collapsed ? "!py-[4px] !px-[4px]" : "!py-[4px] !px-[4px]"}`}
            >
              <MasqueradeSwitcher collapsed={collapsed} />
            </div>
            <ul
              className={`space-y-2 font-medium ${theme === "dark" ? "text-white" : "text-gunmetal"}`}
            >
              <NavigationMenu.Root
                asChild
                orientation="vertical"
                value={menuValue}
              >
                <nav>
                  <NavigationMenu.List asChild>
                    <XSMed as="ul" className="group capitalize">
                      {navLinks
                        ?.filter(
                          (link) =>
                            !link.hidden &&
                            (!link.adminOnly || isMasqueradeAdmin)
                        )
                        ?.map(({ ...props }) => {
                          return (
                            <DashboardLink
                              mode={collapsed ? "onlyIcon" : "default"}
                              key={props.label}
                              setMenuValue={setMenuValue}
                              toggleSideBar={toggle}
                              theme={theme}
                              {...props}
                            />
                          )
                        })}
                    </XSMed>
                  </NavigationMenu.List>
                </nav>
              </NavigationMenu.Root>
            </ul>
          </div>
          {(profileCheckPoints?.length ?? 0) > 0 && theme === "dark" && (
            <div className="hide-on-mobile">
              <ProfileCompletionState collapsed={collapsed} />
            </div>
          )}
          {(checkPoints?.length ?? 0) > 0 &&
            variant === "personalDashboard" && (
              <div className="hide-on-mobile">
                <UserProfileCompletionState collapsed={collapsed} />
              </div>
            )}
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
