import React from "react"
import clsx from "clsx"
import UserMenuOpener from "@/components/UserMenu/Opener"
import HeaderTextDrawer from "../HeaderTextDrawer"
import MessagingTrigger from "../messaging/Trigger"

interface Props {
  theme: "dark" | "default" | "bone" | "withoutBorder"
  children: React.ReactNode | undefined
}

const Header = React.memo(({ theme = "default", children }: Props) => {
  return (
    <div
      className={clsx(
        "sticky top-0 w-100 flex align-middle items-center pl-[40px] pr-[20px] py-3 z-20 h-[52px] font-medium justify-between",
        {
          "bg-offblack": theme === "dark",
          // Removed border according to Figma
          // "bg-white border-b border-gray-200": theme === "default",
          "bg-white": theme === "default",
          // "bg-spring border-spring": theme === "bone",
          "bg-white border-none": theme === "withoutBorder",
        }
      )}
    >
      <HeaderTextDrawer theme={theme} />
      <MessagingTrigger className="border-l border-cream border-opacity-30 px-3 sr-only" />
      {children}
      <UserMenuOpener />
    </div>
  )
})

export default Header
