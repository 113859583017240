import { useCallback, useMemo } from "react"
import {
  Checkpoints,
  getCheckpointsByRoleAndUserType,
} from "@/components/UserOnboarding/utils"
import useAuth from "@/hooks/useAuth"
import API from "@/lib/api"

export default function useUserCompletion() {
  const { user, setUser } = useAuth()

  const userTeam = user?.teams?.[0]
  const myTeamUser = userTeam?.all_members?.find(
    ({ email }) => email === user?.email
  )

  const isTeamAdmin =
    myTeamUser?.member_type === "Admin" || myTeamUser?.member_type === "Owner"

  const markAsCompleted = useCallback(
    async (checkpoint: Checkpoints) => {
      await API.patch("/api/user/", {
        metadata: {
          ...user?.metadata,
          completionsDone: [
            ...(user?.metadata?.completionsDone ?? []),
            checkpoint,
          ],
        },
      })
      setUser({
        ...user,
        metadata: {
          ...user?.metadata,
          completionsDone: [
            ...(user?.metadata?.completionsDone ?? []),
            checkpoint,
          ],
        },
      })
    },
    [setUser, user]
  )

  const checkPoints = useMemo(() => {
    return getCheckpointsByRoleAndUserType(isTeamAdmin ? "admin" : "member")
  }, [isTeamAdmin])

  const completionsDone = useMemo(() => {
    // Verification always will be done
    return [
      Checkpoints.VERIFY_ACCOUNT,
      ...(user?.metadata?.completionsDone ?? []),
    ]
  }, [user?.metadata?.completionsDone])

  const mileStones = useMemo(() => {
    return checkPoints
      .map((checkpoint) => ({
        id: checkpoint.key,
        completed: completionsDone?.includes(checkpoint.key),
      }))
      .sort((a, b) => {
        if (a.completed && !b.completed) {
          return -1
        }
        if (!a.completed && b.completed) {
          return 1
        }
        return 0
      })
  }, [checkPoints, completionsDone])

  const allMilestonesCompleted = useMemo(
    () => mileStones.every((milestone) => milestone.completed),
    [mileStones]
  )

  return useMemo(() => {
    return {
      checkPoints,
      markAsCompleted,
      completionsDone,
      allMilestonesCompleted,
    }
  }, [allMilestonesCompleted, checkPoints, completionsDone, markAsCompleted])
}
