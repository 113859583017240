/* eslint-disable no-unsafe-optional-chaining */

import React, {
  ChangeEventHandler,
  FC,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"

import useAuth from "hooks/useAuth"
import Image from "components/Image"
import { H4, SerifH5, SerifH7, XSmall, XSSmall } from "@/styles/Type"
import { ArtistOrBrand } from "@/types/ArtistOrBrand"
import dynamic from "next/dynamic"
import { useRouter } from "next/navigation"
import CheckmarkIcon from "@/icons/Checkmark"
import AngleDownIcon from "@/icons/AngleDown"
import MiniPill from "@/components/MiniPill"
import { clearMasqueradeServer, setMasqueradeServer } from "@/lib/serverActions"
import { useAppDispatch } from "@/store/store"
import { authActions } from "@/store/auth/authSlice"
import numberFormat from "@/lib/numberFormat"
import { useRecentMasqueradeIds } from "@/hooks/useRecentMasquerades"
import useProfileConnections from "@/hooks/useProfileConnections"
import useUserCompletion from "@/hooks/useUserCompletion"
import { FixedSizeList, areEqual } from "react-window"

const PortfolioSearch = dynamic(() => import("@/components/PortfolioSearch"))

interface DropdownItemProps {
  data: {
    items: ArtistOrBrand[]
    insertRecentProfileId: (id: number) => void
    getProfileImageSrc: (profile: ArtistOrBrand) => string | null
  }
  index: number
  style: React.CSSProperties
}
const DropdownItem = React.memo(({ data, index, style }: DropdownItemProps) => {
  const { masquerade, setMasquerade } = useAuth()
  const dispatch = useAppDispatch()
  const router = useRouter()

  const { items, insertRecentProfileId, getProfileImageSrc } = data
  const { type, slug, name, hero, ...rest } = items[index]
  return (
    <DropdownMenu.Item asChild key={[type, slug].join("-")} textValue="">
      <button
        style={style}
        disabled={masquerade?.slug === slug}
        type="button"
        className="flex items-center ml-[0] p-2 rounded-md focus:bg-buff focus:outline-none hover:bg-buff transition duration-300 text-left mt-[8px] cursor-pointer px-3 box-border w-[276px] h-[56px]"
        onClick={async () => {
          insertRecentProfileId(rest.id)
          setMasquerade(type, { hero, name, slug, ...rest })
          dispatch(authActions.setMasqueradeSwitchLoading(true))
          await setMasqueradeServer(type, slug)
          router.replace(`${type === "artist" ? "/artist" : "/brand"}/${slug}`)
        }}
      >
        <div className="mr-3 min-w-[40px] w-[40px] h-[40px] bg-quill">
          {getProfileImageSrc({ hero, ...rest } as any) && (
            <Image
              src={getProfileImageSrc({ hero, ...rest } as any)}
              width="40"
              height="40"
              alt={name}
            />
          )}
        </div>
        <div className="flex flex-col">
          <H4
            as="h2"
            className="flex-grow max-w-[114px] whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            {name}
          </H4>
          <XSmall className="text-[rgba(22,22,22,0.39)]">
            ({`${type.charAt(0).toUpperCase()}${type.slice(1)}`} profile)
          </XSmall>
        </div>
        {rest?.status === "Draft" && (
          <MiniPill className="mr-3 ml-[5px]" theme="draft">
            Draft
          </MiniPill>
        )}
        {masquerade?.slug === slug && (
          <CheckmarkIcon className="ml-3" color="rgba(160, 125, 38, 1)" />
        )}
      </button>
    </DropdownMenu.Item>
  )
}, areEqual)

const MasqueradeList = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearchTermChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSearchTerm(e.currentTarget.value)
  }, [])
  const [recentProfileIds, insertRecentProfileId] = useRecentMasqueradeIds()

  const { user, masquerade, clearMasquerade } = useAuth()
  const { artist_profiles, brand_profiles } = user ?? {}
  const myProfile = useMemo(() => {
    return {
      hero: user?.profile_image,
      name: `${user?.first_name} ${user?.last_name}`,
      slug: "me",
    }
  }, [user])

  const profiles = useMemo<ArtistOrBrand[]>(() => {
    const artists = artist_profiles ?? []
    const brands = brand_profiles ?? []

    return [
      ...artists.map((artistProfile) => ({
        ...artistProfile,
        type: "artist" as "artist",
      })),
      ...brands.map((artistProfile) => ({
        ...artistProfile,
        type: "brand" as "brand",
      })),
    ]
      .filter(({ status }) => status !== "Denied")
      .sort((artist, brand) => artist?.name?.localeCompare(brand.name))
      .sort((artist, brand) => {
        const artistIdx = artist.slug === masquerade?.slug ? -1 : 0
        const brandIdx = brand.slug === masquerade?.slug ? -1 : 0
        return artistIdx - brandIdx
      })
  }, [artist_profiles, brand_profiles, masquerade?.slug])

  const filteredProfiles = useMemo(() => {
    const priorityProfiles = recentProfileIds.map((recentId) =>
      profiles.find((profile) => profile.id === recentId)
    )

    const remainingFiltered = profiles.filter(
      (profile) => !recentProfileIds.includes(profile.id)
    )

    if (!searchTerm.trim()) {
      return [...priorityProfiles, ...remainingFiltered].filter(
        (value) => value
      )
    }

    return [...priorityProfiles, ...remainingFiltered].filter((profile) => {
      if (!profile) {
        return false
      }
      const term = searchTerm.toLowerCase()
      const nameMatch = profile?.name?.toLowerCase()?.includes(term)
      const ownerFirstNameMatch = profile?.owner?.first_name
        .toLowerCase()
        .includes(term)
      const ownerLastNameMatch = profile?.owner?.last_name
        .toLowerCase()
        .includes(term)
      return nameMatch || ownerFirstNameMatch || ownerLastNameMatch
    })
  }, [profiles, recentProfileIds, searchTerm])

  const getProfileImageSrc = useCallback((profile: ArtistOrBrand) => {
    if (profile.hero && profile.hero.image_src) {
      return profile.hero.image_src
    }
    if ("logo" in profile && profile.logo?.image_src) {
      return profile.logo.image_src
    }
    return null
  }, [])

  const dropdownItemsData = useMemo(() => {
    return {
      items: filteredProfiles,
      insertRecentProfileId,
      getProfileImageSrc,
    }
  }, [filteredProfiles, insertRecentProfileId, getProfileImageSrc])

  return (
    <DropdownMenu.Content
      loop
      align="end"
      sideOffset={6}
      alignOffset={-10}
      className={clsx(
        "overflow-hidden h-[421px] w-[298px] rounded-lg border border-[#E0E0E0] shadow-[4px_4px_4px_rgba(0,0,0,0.08)] z-20 bg-spring text-offblack flex flex-col animate-fall-in lg:mr-2.5 lg:mt-2.5 ml-[12px] pb-[18px]"
      )}
    >
      {profiles.length > 0 && (
        <DropdownMenu.Group className="px-2 flex flex-col flex-grow">
          <div className="sticky bg-spring top-0 z-10 pt-2">
            <PortfolioSearch
              onLoadMore={() => {}}
              onShowFullSearch={() => {}}
              searchResultsCount={0}
              onChange={handleSearchTermChange}
              searchTerm={searchTerm}
              clearSearch={() => setSearchTerm("")}
              searchResults={[]}
            />

            <DropdownMenu.Item asChild key="me" textValue="">
              <>
                <button
                  disabled={!masquerade?.slug}
                  type="button"
                  className="flex items-center -m-2 mb-2 last:-mb-2 p-2 rounded-md focus:bg-buff focus:outline-none hover:bg-buff transition duration-300 text-left mt-[7px] cursor-pointer ml-1 px-2 box-border w-[268px]"
                  onClick={async () => {
                    clearMasquerade(true)
                    dispatch(authActions.setMasqueradeSwitchLoading(true))
                    await clearMasqueradeServer()
                    setTimeout(() => {
                      dispatch(authActions.setMasqueradeSwitchLoading(false))
                      router.replace("/dashboard/entities")
                    }, 1000)
                  }}
                >
                  <div className="mr-3 min-w-[40px] w-[40px] h-[40px] bg-quill rounded-full flex items-center justify-center">
                    {myProfile?.hero ? (
                      <Image
                        className="rounded-full"
                        src={myProfile.hero}
                        width="40"
                        height="40"
                        alt={myProfile.name}
                      />
                    ) : (
                      <SerifH5 className="text-[#454542] mt-1">
                        {myProfile?.name?.slice(0, 1)}
                      </SerifH5>
                    )}
                  </div>
                  <H4 as="h2" className="flex-grow">
                    {myProfile?.name}{" "}
                    <span className="text-[rgba(22,22,22,0.39)] tracking-[0.14px]">
                      (You)
                    </span>
                  </H4>
                  {!masquerade?.slug && (
                    <CheckmarkIcon color="rgba(160, 125, 38, 1)" />
                  )}
                </button>
                <div className="border-b border-b-[rgba(0,0,0,0.06)] ml-[-8px] mb-[-3px] w-[292px]" />
              </>
            </DropdownMenu.Item>
          </div>
          <FixedSizeList
            itemData={dropdownItemsData}
            itemSize={56}
            height={280}
            itemCount={filteredProfiles?.length ?? 0}
            width={287}
          >
            {DropdownItem}
          </FixedSizeList>
        </DropdownMenu.Group>
      )}
    </DropdownMenu.Content>
  )
}

export const MasqueradeSwitcher: FC<
  HTMLAttributes<HTMLDivElement> & {
    collapsed: boolean
  }
> = ({ className, collapsed }) => {
  const { masquerade, user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const { connectionsForSingleProfile } = useProfileConnections()
  const { checkPoints, completionsDone } = useUserCompletion()

  const completionsAllDone = useMemo(() => {
    if (!checkPoints?.length) {
      return true
    }
    return checkPoints.every((checkpoint) =>
      completionsDone?.includes(checkpoint.key)
    )
  }, [checkPoints, completionsDone])

  const accountDetails = () => {
    if (masquerade?.type) {
      return (
        <div
          style={{ display: collapsed ? "none" : undefined }}
          className="flex flex-col mt-2 w-full items-start whitespace-nowrap"
        >
          <div className="flex gap-2 items-start">
            <SerifH5
              style={{ fontSize: "19px" }}
              className="font-semibold whitespace-nowrap max-w-[140px] overflow-hidden overflow-ellipsis"
            >
              {masquerade?.name}
            </SerifH5>
            {masquerade?.status === "Draft" ? (
              <div className="text-[0.75rem] ml-[5px] lg:text-[0.625rem] leading-[2] tracking-[0.04em] uppercase font-medium rounded-full px-2 inline-block mr-3 bg-warning text-offblack">
                Draft
              </div>
            ) : (
              <MiniPill className="sr-only" theme="gold">
                <p style={{ fontSize: "12px" }}>9.3%</p>
              </MiniPill>
            )}
          </div>
          <div className="flex gap-3">
            <XSmall className="text-medgray">
              {" "}
              {!connectionsForSingleProfile?.totalValue
                ? "N/A"
                : numberFormat(connectionsForSingleProfile?.totalValue)}{" "}
              connections
            </XSmall>
          </div>
        </div>
      )
    }
    return (
      <div
        style={{ display: collapsed ? "none" : undefined }}
        className="flex mt-[12px] w-full flex-col items-start whitespace-nowrap max-w-[200px]"
      >
        <SerifH7
          className="font-semibold flex items-center whitespace-nowrap max-w-[180px] overflow-hidden overflow-ellipsis"
          style={{ fontSize: "19px" }}
        >
          {/* /// here/// */}
          <span className="text-start overflow-hidden overflow-ellipsis w-[150px] block">
            {user?.first_name} {user?.last_name}
          </span>
          {user?.status === "Guest" && (
            <div className="text-[0.75rem] ml-[5px] lg:text-[0.625rem] leading-[2] tracking-[0.04em] uppercase font-medium rounded-full px-2 inline-block mr-3 bg-[#EDEDE9] text-[#6D6D6D]">
              Guest
            </div>
          )}
        </SerifH7>
        <XSmall
          className="font-greyBr text-start"
          style={{
            color: "#8A8A85",
            lineHeight: "20px",
            letterSpacing: "0.12px",
          }}
        >
          {user?.job_title}
        </XSmall>
      </div>
    )
  }

  const accountAvatar = () => {
    if (user?.profile_image && !masquerade?.type) {
      return (
        <Image
          className="rounded-full"
          src={user?.profile_image}
          alt={[user?.first_name, user?.last_name].join(" ")}
          width="40"
          height="40"
          sizes="40px"
        />
      )
    }

    if (
      masquerade?.type &&
      (("logo" in masquerade && masquerade?.logo?.image_src) ||
        masquerade?.hero?.image_src)
    ) {
      return (
        <Image
          src={
            ("logo" in masquerade && masquerade?.logo?.image_src) ||
            masquerade?.hero.image_src
          }
          alt={masquerade?.name}
          width="40"
          height="40"
          sizes="40px"
        />
      )
    }

    // Fallback: display the first letter of user's first name if profile image doesn't exist
    if (!masquerade?.name) {
      return (
        <div className="w-[40px] h-[40px] object-cover bg-swisscafe rounded-full flex justify-center items-center">
          <SerifH5 className="mt-1"> {user?.first_name?.charAt(0)}</SerifH5>
        </div>
      )
    }
    return (
      <div className="w-[40px] h-[40px] object-cover bg-swisscafe rounded-full flex justify-center items-center">
        <SerifH5 className="mt-1 text-offblack">
          {" "}
          {masquerade?.name?.charAt(0)}
        </SerifH5>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        "-mr-2.5 lg:px-0 lg:mr-0 order-last",
        "transition duration-150",
        className
      )}
    >
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <DropdownMenu.Trigger className="w-full flex justify-between items-center focus-visible:outline-none focus-visible:shadow-none">
          <div className="w-full">
            <div className="flex justify-between w-full">
              <div
                className={clsx(
                  "flex w-10 h-10 rounded-full overflow-hidden relative"
                )}
              >
                {accountAvatar()}
              </div>
              {user?.id &&
                !masquerade?.name &&
                !completionsAllDone &&
                !collapsed && (
                  <XSSmall>
                    <span className="w-[77px] h-[19px] bg-[#EBD7FF] text-offblack uppercase rounded-[10px] block text-center">
                      unfinished
                    </span>
                  </XSSmall>
                )}
            </div>
            {accountDetails()}
          </div>
          {!collapsed && (
            <AngleDownIcon
              width="16"
              height="16"
              className="rotate-180 mt-[30px]"
            />
          )}
        </DropdownMenu.Trigger>
        {isOpen && (
          <div
            className={clsx(
              "fixed inset-0 bg-offblack bg-opacity-50 z-10",
              "transform-none",
              "animate-fade-in"
            )}
          />
        )}
        <MasqueradeList />
      </DropdownMenu.Root>
    </div>
  )
}
export default MasqueradeSwitcher
