import React from "react"

import useAuth from "@/hooks/useAuth"
import { usePathname } from "next/navigation"
import Breadcrumb from "./Breadcrumb"

const HeaderTextDrawer = ({ theme }) => {
  const { user, masquerade } = useAuth()
  const pathname = usePathname()
  const currentModule = pathname.split("/")[2]

  return (
    <div
      className={`${
        theme === "dark" ? "bg-transparent z-[99] w-[350px]" : ""
      } flex items-center gap-4 align-middle "`}
    >
      {masquerade?.name ? (
        <div className="text-medgray text-[10px] sm:text-[12px]">
          <Breadcrumb
            separator="/"
            containerClasses="flex items-center gap-4 align-middle"
            listClasses="text-medgray"
            activeClasses="text-medgray"
            capitalizeLinks={false}
          />
        </div>
      ) : (
        <div className="flex items-center gap-[5px]">
          <span className="font-sans text-[12px] text-gunmetal">
            {user?.first_name}
          </span>
          <span className="text-gunmetal">/</span>
          <span className="font-sans text-[12px] text-[#8A8A84] capitalize">
            {currentModule}
          </span>
        </div>
      )}
    </div>
  )
}

export default HeaderTextDrawer
