export default function debounce(func: Function, wait: number) {
  let timeout: ReturnType<typeof setTimeout>
  return function debounced(...args: any[]) {
    const later = () => {
      timeout = null
      // @ts-ignore
      func.apply(this, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
