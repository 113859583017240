import { H3, XSmall } from "@/styles/Type"
import { ArrowRightIcon } from "@/icons/ArrowRight"
import React, { Suspense, useCallback } from "react"
import { CloseIcon } from "@/icons/Close"

import clsx from "clsx"

import useUserCompletion from "@/hooks/useUserCompletion"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { commonSelectors } from "@/store/common/selectors"
import { commonActions } from "@/store/common/commonSlice"
import REDUX_MODAL_KEYS from "@/lib/modals"
import useAuth from "@/hooks/useAuth"
import Button from "../Button"

const ChecklistModal = React.lazy(() => import("./ChecklistModal"))

const UserProfileCompletionState = React.memo(
  ({ collapsed }: { collapsed: boolean }) => {
    const { user } = useAuth()
    const dispatch = useAppDispatch()
    const modals = useAppSelector(commonSelectors.modals)
    const { checkPoints, completionsDone } = useUserCompletion()

    const handleToggle = useCallback(
      (visible = false) => {
        dispatch(
          commonActions.setModal({
            key: REDUX_MODAL_KEYS.draftUserChecklist,
            visible,
          })
        )
      },
      [dispatch]
    )

    const mileStones = checkPoints
      .map((checkpoint) => ({
        id: checkpoint.key,
        completed: completionsDone?.includes(checkpoint.key),
      }))
      .sort((a, b) => {
        if (a.completed && !b.completed) {
          return -1
        }
        if (!a.completed && b.completed) {
          return 1
        }
        return 0
      })

    const allMilestonesCompleted = mileStones.every(
      (milestone) => milestone.completed
    )

    const isDialogOpen = modals?.draftUserChecklist

    if (allMilestonesCompleted || !user?.metadata) {
      return null
    }

    return (
      <div className="p-2">
        {!collapsed && (
          <div className="border-[1px] rounded-md border-[#E5DFD0] p-3 relative w-full mb-[20px]">
            <CloseIcon className="absolute cursor-pointer top-2 right-2 sr-only" />
            <H3 as="a" className="text-offblack mb-2 font-medium">
              Get Started
            </H3>
            <div className="flex gap-1 mt-2">
              {mileStones.map((milestone, index) => (
                <div
                  key={milestone.id}
                  className={clsx(
                    "w-[39px] h-[9px]",
                    index === 0 ? "rounded-l-md" : "",
                    index === mileStones.length - 1 ? "rounded-r-md" : "",
                    milestone.completed ? "bg-[#C7EA26]" : "bg-[#E5DFD099]"
                  )}
                />
              ))}
            </div>
            <div className="mt-2">
              <XSmall className="text-[#454542]">
                Get familiar with Lumina by completing these tasks
              </XSmall>
            </div>
            <Button
              className="mt-6 mb-2 text-bronze font-medium !transition-none"
              theme="custom"
              size="custom"
              onClick={() => handleToggle(true)}
              disableTransition
            >
              My Checklist
              <ArrowRightIcon stroke="" className="ml-2" />
            </Button>
          </div>
        )}
        <Suspense fallback={null}>
          <ChecklistModal open={isDialogOpen} onOpenChange={handleToggle} />
        </Suspense>
      </div>
    )
  }
)

UserProfileCompletionState.displayName = "UserProfileCompletionState"

export default UserProfileCompletionState
