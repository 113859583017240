import { REQUEST_STATUS, REQUEST_SUB_STATUS } from "@/types/RequestStatus"
import { Request } from "@/types/Request"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { OutboundRequest } from "@/components/Cell/ActionCell/types"
import React from "react"

// Extend Day.js with the relativeTime plugin
dayjs.extend(relativeTime)

const getRequestStatusName = (
  status: REQUEST_STATUS,
  request: Request | OutboundRequest
) => {
  switch (status) {
    case REQUEST_STATUS.NEW:
      return "New Request"
    case REQUEST_STATUS.IN_CONVERSATION:
      if (
        request.status === REQUEST_STATUS.IN_CONVERSATION &&
        [REQUEST_SUB_STATUS.NEW, REQUEST_SUB_STATUS.GRANTED].includes(
          request.sub_status
        )
      ) {
        return "New Request"
      }
      return "Proposal"
    case REQUEST_STATUS.UNDER_CONTRACT:
    case REQUEST_STATUS.PENDING_SIGNATURE:
    case REQUEST_STATUS.PENDING_CONFIRMATION:
      if (
        request.status === REQUEST_STATUS.IN_CONVERSATION &&
        [REQUEST_SUB_STATUS.NEW, REQUEST_SUB_STATUS.GRANTED].includes(
          request.sub_status
        )
      ) {
        return "Proposal"
      }
      return "Contract phase"
    case REQUEST_STATUS.ACTIVE:
      return "Open project"
    case REQUEST_STATUS.COMPLETED:
      return "Completed"
    case REQUEST_STATUS.ARCHIVED:
      return "Archived"
    default:
      return status
  }
}

const isUserInProjectPage = (pathName: string, projectSlug?: string) => {
  if (
    pathName.includes("/dashboard/outbound") ||
    pathName.includes("/dashboard/inbox")
  ) {
    return true
  }
  return (
    pathName.startsWith("/dashboard/projects/") &&
    pathName.includes(projectSlug)
  )
}

function generateRandomId(length = 6) {
  let id = ""
  for (let i = 0; i < length; i += 1) {
    id += Math.floor(Math.random() * 10)
  }
  return id
}

function markSearchTerm(text: string, searchTerm: string) {
  const lowerText = text.toLowerCase()
  const lowerSearchTerm = searchTerm.toLowerCase()
  const index = lowerText.indexOf(lowerSearchTerm)
  if (index === -1) {
    return (
      <>
        {text.split("|").map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < text.split("|").length - 1 && (
              <span className="px-[12px]">|</span>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }
  return (
    <>
      {text.slice(0, index)}
      <span className="text-[#1E1E1E]">
        {text.slice(index, index + searchTerm.length)}
      </span>
      {text
        .slice(index + searchTerm.length)
        .split("|")
        .map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < text.split("|").length - 1 && (
              <span className="px-[12px]">|</span>
            )}
          </React.Fragment>
        ))}
    </>
  )
}

function toTimeAge(date: string) {
  if (!date) {
    return ""
  }
  const d = new Date(date)
  const someTimestamp = dayjs(d)
  return someTimestamp.fromNow()
}

const truncateText = (text = "", maxLength = 10) => {
  if (!text) {
    return ""
  }
  if (text?.length > maxLength) {
    return `${text.slice(0, maxLength)}...`
  }
  return text
}

// eslint-disable-next-line import/prefer-default-export
export {
  getRequestStatusName,
  isUserInProjectPage,
  generateRandomId,
  markSearchTerm,
  toTimeAge,
  truncateText,
}
