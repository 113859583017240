"use client"

import React, { ReactNode } from "react"
import { usePathname } from "next/navigation"
import Link from "next/link"

type TBreadCrumbProps = {
  separator: ReactNode
  containerClasses?: string
  listClasses?: string
  activeClasses?: string
  capitalizeLinks?: boolean
}

const removeHyphensAndCapitalize = (text: string) => {
  return text
    .split("-")
    .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
    .join(" ")
}

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
}

const formatLinkText = (text: string, capitalizeLinks: boolean) => {
  const cleanedText = removeHyphensAndCapitalize(text)
  const truncatedText = truncateText(cleanedText, 60)
  return capitalizeLinks ? truncatedText : cleanedText
}

const checkWorkspaceNaming = (pathNames: string[]): string[] => {
  const dashboardIndex = pathNames.indexOf("dashboard")

  // Replace "dashboard/projects" with "inbound"
  if (dashboardIndex !== -1 && pathNames[dashboardIndex + 1] === "projects") {
    return pathNames
      .map((pathName, index) =>
        index === dashboardIndex ? "inbound" : pathName
      )
      .filter(
        (pathName, index) =>
          !(pathName === "projects" && index === dashboardIndex + 1)
      )
  }

  return pathNames
}

const revertWorkspaceNaming = (paths: string): string => {
  const pathNames = paths.split("/").filter((path) => path)
  const dashboardIndex = pathNames.indexOf("inbound")

  // Revert "inbound" back to "dashboard/projects"
  if (dashboardIndex !== -1) {
    return [
      ...pathNames.slice(0, dashboardIndex),
      "dashboard",
      "projects",
      ...pathNames.slice(dashboardIndex + 1),
    ].join("/")
  }

  return pathNames.join("/")
}

const NextBreadcrumb = ({
  separator,
  containerClasses,
  listClasses,
  activeClasses,
  capitalizeLinks = true,
}: TBreadCrumbProps) => {
  const paths = usePathname()
  const pathNames = checkWorkspaceNaming(
    paths.split("/").filter((path) => path)
  )

  return (
    <div className={containerClasses}>
      <ul className="flex space-x-2 overflow-hidden">
        {pathNames.map((link, index) => {
          let href = revertWorkspaceNaming(
            `${pathNames.slice(0, index + 1).join("/")}`
          )

          if (
            link.toLocaleLowerCase() === "brand" ||
            link.toLocaleLowerCase() === "artist" ||
            link.toLocaleLowerCase() === "workspace"
          ) {
            href = "discover"
          }

          if (
            link.toLocaleLowerCase() === "projects" &&
            index > 0 &&
            pathNames[index - 1].toLocaleLowerCase() === "projects"
          ) {
            href = `${pathNames.slice(0, index).join("/")}`
          }

          const itemClasses =
            paths === href ? `${listClasses} ${activeClasses}` : listClasses
          const itemLink = formatLinkText(link, capitalizeLinks)

          return (
            <React.Fragment key={index}>
              <span
                className={`whitespace-nowrap text-[10px] sm:text-[12px] font-sans overflow-hidden ${
                  index === 0 ? "text-[#454542]" : "text-[#454542]"
                } ${itemClasses}`}
              >
                <Link href={`/${href}`}>{itemLink}</Link>
              </span>
              {pathNames.length !== index + 1 && (
                <span className="whitespace-nowrap text-[12px] font-sans text-[#454542]">
                  {separator}
                </span>
              )}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default NextBreadcrumb
