import CheckmarkIcon from "@/icons/Checkmark"
import Settings from "@/icons/Settings"
import Community from "@/icons/Community"
import ProfileIcon from "@/icons/Profile"
import MessageIcon from "@/icons/MessageIcon"
import Company from "@/icons/Company"

// eslint-disable-next-line import/prefer-default-export
export enum Checkpoints {
  VERIFY_ACCOUNT = "VERIFY_ACCOUNT",
  FINALIZE_PROFILE = "FINALIZE_PROFILE",
  FIND_CONTACTS = "FIND_CONTACTS",
  REVIEW_PROFILES = "REVIEW_PROFILES",
  COMPLETE_COMPANY_DETAILS = "COMPLETE_COMPANY_DETAILS",
  CHECK_INBOX = "CHECK_INBOX",
}

export const ALL_CHECKPOINTS = [
  {
    key: Checkpoints.VERIFY_ACCOUNT,
    title: "Verify your account",
    Icon: ProfileIcon,
    IconCompleted: CheckmarkIcon,
    types: ["member", "admin"],
  },
  {
    key: Checkpoints.FINALIZE_PROFILE,
    title: "Finalize your personal details",
    Icon: Settings,
    IconCompleted: CheckmarkIcon,
    types: ["member", "admin"],
    link: "/dashboard/settings",
  },
  {
    key: Checkpoints.COMPLETE_COMPANY_DETAILS,
    title: "Complete your company details",
    Icon: Company,
    IconCompleted: CheckmarkIcon,
    types: ["admin"],
    link: "/dashboard/company",
  },
  {
    key: Checkpoints.FIND_CONTACTS,
    title: "Find your contacts on Lumina",
    Icon: Community,
    IconCompleted: CheckmarkIcon,
    types: ["member", "admin"],
    link: "/dashboard/community",
  },
  {
    key: Checkpoints.CHECK_INBOX,
    title: "Check your inbox",
    Icon: MessageIcon,
    IconCompleted: CheckmarkIcon,
    types: ["member", "admin"],
    link: "/dashboard/messaging",
  },
  {
    key: Checkpoints.REVIEW_PROFILES,
    title: "Review your profile(s)",
    Icon: ProfileIcon,
    IconCompleted: CheckmarkIcon,
    types: ["member", "admin"],
    link: "/dashboard/entities",
  },
]

export const CHECKPOINT_SIDEBAR_ITEM_MAP = {
  [Checkpoints.FINALIZE_PROFILE]: "Settings",
  [Checkpoints.COMPLETE_COMPANY_DETAILS]: "Company",
  [Checkpoints.FIND_CONTACTS]: "Community",
  [Checkpoints.CHECK_INBOX]: "Messaging",
  [Checkpoints.REVIEW_PROFILES]: "Entities",
}

export function getCheckpointsByRoleAndUserType(userType: "admin" | "member") {
  return ALL_CHECKPOINTS.filter((checkpoint) =>
    checkpoint.types.includes(userType)
  )
}
